const parseCookies = (cookieString = document.cookie) => {
  const cookies = cookieString.split(';');
  const result = {};

  cookies.forEach((cookieString) => {
    const [key, ...value] = cookieString.split('=');
    if (key !== '') result[key.trim()] = value.join('=');
  });

  return result;
};

const getConsentCookie = (cookies = parseCookies()) => {
  return cookies?.OptanonConsent;
};

const parseConsentCookie = (consentCookie = getConsentCookie()) => {
  if (!consentCookie) return {};

  const decoded = consentCookie.split('&').map((x) => decodeURIComponent(x));

  return decoded.reduce((acc, x) => {
    const [key, ...values] = x.split('=');
    acc[key.trim()] = values.join('=');
    return acc;
  }, {});
};

const getFbpCookie = (cookies = parseCookies()) => {
  return cookies?._fbp;
};

const parseFbpCookie = (fbpCookie = getFbpCookie()) => {
  if (!fbpCookie) return {};

  const createdAtString = fbpCookie.split('.')[2];

  return {
    value: fbpCookie,
    ...(createdAtString && { createdAt: new Date(Number(createdAtString)) })
  };
};

const getBabbelTrackingUuid = (cookies = parseCookies()) => {
  return cookies?.babbeltrackinguuid;
};

export { parseCookies, parseConsentCookie, getConsentCookie, getFbpCookie, parseFbpCookie, getBabbelTrackingUuid };

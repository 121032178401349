import { currentHost } from './url';

const ENVIRONMENTS = [
  { id: 'production', hostPattern: /babbel\.com$/i },
  { id: 'production', hostPattern: /babbelfuerunternehmen\.de$/i },
  { id: 'production', hostPattern: /business\.babbel\.com$/i },
  { id: 'staging', hostPattern: /babbel\.cn$/i },
  { id: 'staging', hostPattern: /babbel-for-business\.(preview|site)\.strattic\.io$/i },
  { id: 'staging', hostPattern: /babbel-b2b\.(preview|site)\.strattic\.io$/i },
  { id: 'staging', hostPattern: /babbel-b2b-eng\.(preview|site)\.strattic\.io$/i },
  { id: 'development', hostPattern: /localhost(:[0-9]+)?$/i },
  { id: 'development', hostPattern: /localtest\.me/i }
];

const DEFAULT_ENV = 'development';

const currentEnvironment = () => {
  const match = ENVIRONMENTS.find((e) => new RegExp(e.hostPattern).test(currentHost()));
  return match ? match.id : DEFAULT_ENV;
};

export { currentEnvironment };

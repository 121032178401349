import { stringify, parse } from './utils/queryString';
import { documentReady } from './utils/documentReady';
import { get } from './utils/api';

const extractQueryFromExtras = (extras) => {
  const queryVariables = stringify(extras);

  return queryVariables.length === 0 ? '' : `?${queryVariables}`;
};

const createAdsContainer = async () => {
  const adsContainerId = 'ads-container';
  const div = document.createElement('div');
  div.id = adsContainerId;
  div.style = 'display:none';
  await documentReady();
  document.body.appendChild(div);
};

const currentUrl = () => window.location.href;

const getContentGroup = () => {
  const tag = document.querySelector('meta[name="marketing_content_group"]');
  return tag ? tag.content : '';
};

const getProductCategory = () => {
  const tag = document.querySelector('meta[name="product_category"]');
  return tag ? tag.content : '';
};

const params = () =>
  stringify({
    dr: document.referrer,
    pt: getContentGroup(),
    dl: window.location.href,
    pc: getProductCategory(),
    v: __APP_VERSION__
  });

class CampaignsTracker {
  constructor(options) {
    if (options && options.host) {
      this.host = options.host.replace(/\/$/, '');
    } else {
      throw new Error('Host should be set.');
    }

    createAdsContainer();
  }

  getConversionURL(uuid, eventType, extras = {}) {
    const query = extractQueryFromExtras(extras);
    return `${this.host}/accounts/${uuid}/conversions/${eventType}.js${query}`;
  }

  trackUserClick() {
    const EVENT = 'click';
    const { bsc, btp } = parse(currentUrl());
    let hasClicked = false;

    const clickHandler = () => {
      if (!hasClicked) {
        hasClicked = true;
        document.removeEventListener(EVENT, clickHandler);
        const url = `${this.host}/${bsc}/${btp}/clicked.gif?${params()}`;
        get(url);
      }
    };

    if (bsc && btp) {
      document.addEventListener(EVENT, clickHandler);
    }
  }

  trackSimpleLead(uuid) {
    this.trackLead('simple_lead', uuid);
  }

  trackPageview(shortlink) {
    const url = `${this.host}/${shortlink}/default.gif?${params()}`;
    get(url);
  }

  trackVerifiedLead(uuid) {
    this.trackLead('verified_lead', uuid);
  }

  trackSalesConversion(uuid, { purchase_id, price, currency }) {
    this.trackLead('purchase_finished', uuid, { purchase_id, price, currency });
  }

  // we track leads on campaign events already but we use this to notify
  // google analytics of conversion and also assign attribution as soon as possible
  trackLead(type, uuid, extras) {
    if (!uuid) throw new Error('UUID should be set.');
    const url = this.getConversionURL(uuid, type, extras);
    get(url);
  }
}

export default CampaignsTracker;

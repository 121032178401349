import { currentEnvironment } from './environment';
import { getBabbelTrackingUuid } from 'src/utils/cookies.js';
import { validateAndCleanEventData } from 'src/utils/event.js';

const TRACKER_URLS = {
  production: 'https://api.babbel.io/gamma/v1/events',
  staging: 'https://api.babbel-staging.io/gamma/v1/events',
  development: '/gamma/v1/events'
};

const TRACKER_URL = TRACKER_URLS[currentEnvironment()];

const track = (eventData, url = TRACKER_URL) => {
  const { name, version, payload } = validateAndCleanEventData(eventData);

  const event = {
    name,
    version,
    ...payload,
    tracking_uuid: payload?.tracking_uuid || getBabbelTrackingUuid(),
    created_at: payload?.created_at || new Date().toISOString(),
    ...(payload?.uuid && { uuid: payload.uuid }) // only add uuid if it exists in the payload
  };

  return fetch(url, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ events: [event] }),
    keepalive: true
  });
};

export { track };

import { track } from '../utils/tracker';
import { currentHost } from '../utils/url';
import { isMobileDevice } from '../utils/mobile';
import { getConsentCookie, parseConsentCookie } from '../utils/cookies';
import { waitForResult } from '../utils/timers';
import { trackFbpCookie } from './fbp';

const ONETRUST_URL = 'https://cdn.cookielaw.org/scripttemplates/otSDKStub.js';
const ONETRUST_CONSENT_PREFERENCES_TIMEOUT = 5000; // 5 seconds

// TODO: Move to a configuration file
export const DOMAIN_SCRIPTS = [
  { id: '76f8f301-1d03-46f3-a2dd-342d801d1864', host: /babbel\.com/i },
  { id: '4c3434b9-22f2-44cd-8343-aaf318112c3b', host: /babbel\.cn/i },
  { id: '019330c7-cb0b-78d7-b4ac-a1b835d4947b', host: /localtest\.me/i }
];

const domainScriptId = () => DOMAIN_SCRIPTS.find((ds) => currentHost().match(ds.host) !== null)?.id;

const CONSENT_GROUPS = [
  { name: 'necessary_cookies', default: true, code: 'C0001' },
  { name: 'performance_cookies', default: false, code: 'C0002' },
  { name: 'functional_cookies', default: false, code: 'C0003' },
  { name: 'targeting_cookies', default: false, code: 'C0004' }
];

export const defaultPreferences = () =>
  CONSENT_GROUPS.reduce((acc, { name, default: defaultValue }) => {
    acc[name] = defaultValue;
    return acc;
  }, {});

const getConsentPreferences = () => {
  const preferences = defaultPreferences();
  const groups = getOneTrustConsentPreferencesGroups();

  if (groups) {
    groups.forEach((group) => {
      const [groupCode, value] = group.split(':');
      const consentGroup = CONSENT_GROUPS.find((g) => g.code === groupCode);
      if (consentGroup) preferences[consentGroup.name] = value === '1';
    });
  } else {
    console.warn('Error while parsing consent preferences. Using default preferences.');
  }

  return preferences;
};

const getOneTrustConsentPreferencesGroups = (consentCookie = parseConsentCookie()) => {
  try {
    const cookieParams = new URLSearchParams(consentCookie);
    const groupsValue = cookieParams.get('groups');
    if (!groupsValue) return;
    return groupsValue.split(',');
  } catch (error) {
    console.error('Error while parsing consent cookie:', error);
  }
};

const getConsentProviderLocation = () => {
  if (!window.OneTrust) return {};

  const data = window.OneTrust.getGeolocationData();

  return { country: data.country, state_name: data.state };
};

export const trackUserCookieConsentUpdated = (user) => {
  const hasOneTrustPreferences = isOneTrustConsentPreferencesReady();

  track({
    name: 'user_cookie_consent:updated',
    version: 3,
    payload: {
      created_at: new Date().toISOString(),
      uuid: user.uuid,
      platform: isMobileDevice() ? 'mobile' : 'web',
      type: user.uuid ? 'babbel_user' : 'visitor',
      consent_cookie_raw: hasOneTrustPreferences ? getConsentCookie() : undefined,
      consent_cookie: hasOneTrustPreferences ? parseConsentCookie() : undefined,
      consent_preferences: getConsentPreferences(),
      marketing_tracker_version: __APP_VERSION__,
      consent_provider_used: hasOneTrustPreferences,
      consent_provider_location: getConsentProviderLocation()
    }
  });
};

export const createOnetrustScript = (scriptId) => {
  const scriptContainer = document.createElement('script');
  scriptContainer.src = ONETRUST_URL;
  scriptContainer.async = true;
  scriptContainer.type = 'text/javascript';
  scriptContainer.dataset.domainScript = scriptId;
  scriptContainer.id = 'onetrust-script';
  return scriptContainer;
};

export const appendOneTrustScript = async (scriptContainer) => {
  document.body.appendChild(scriptContainer);
};

const waitForOneTrustConsentPreferences = async (timeout, interval = 200) => {
  const consentReady = await waitForResult(isOneTrustConsentPreferencesReady, timeout, interval);
  if (!consentReady) throw new Error('Timeout');
};

const isOneTrustConsentPreferencesReady = () => !!getOneTrustConsentPreferencesGroups();

const setupConsentTracking = async (user) => {
  window.OptanonWrapper = () => {
    window.OneTrust.OnConsentChanged(() => {
      trackUserCookieConsentUpdated(user);
      trackFbpCookie(user, getConsentPreferences());
    });
  };

  waitForOneTrustConsentPreferences(ONETRUST_CONSENT_PREFERENCES_TIMEOUT)
    .then(() => {
      trackUserCookieConsentUpdated(user);
      trackFbpCookie(user, getConsentPreferences());
    })
    .catch((error) => {
      console.warn('Error waiting for One Trust consent preferences:', error);
      console.warn('Using default preferences.');
      trackUserCookieConsentUpdated(user);
    });
};

const loadOnetrust = async (user) => {
  setupConsentTracking(user);

  if (!document.getElementById('onetrust-script')) {
    const scriptId = domainScriptId();
    if (scriptId) {
      appendOneTrustScript(createOnetrustScript(scriptId));
    } else {
      console.warn('OneTrust script ID is not found for the current domain');
    }
  }
};

export { loadOnetrust, getConsentPreferences };

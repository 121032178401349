export function validateAndCleanEventData(eventData) {
  if (typeof eventData !== 'object' || eventData === null) {
    throw new Error('Event data must be an object');
  }

  const { name, version, ...payload } = eventData;

  if (typeof name !== 'string' || name.trim() === '') {
    throw new Error('Event name must be a non-empty string');
  }

  const cleanedPayload = cleanEventPayload(payload);

  return {
    name,
    version,
    ...(cleanedPayload || {})
  };
}

function cleanEventPayload(value) {
  if (value === null || value === undefined) return;
  if (isAcceptedType(value)) return value;

  if (Array.isArray(value)) {
    const cleanedArray = value.map(cleanEventPayload).filter((item) => item !== undefined);
    return cleanedArray.length > 0 ? cleanedArray : undefined;
  }

  if (typeof value === 'object') {
    const cleanedObject = Object.fromEntries(
      Object.entries(value)
        .map(([key, val]) => [key, cleanEventPayload(val)])
        .filter(([, val]) => val !== undefined)
    );
    return Object.keys(cleanedObject).length > 0 ? cleanedObject : undefined;
  }
}

function isAcceptedType(value) {
  return (
    (typeof value === 'number' && !isNaN(value)) ||
    (typeof value === 'string' && value.trim() !== '') ||
    typeof value === 'boolean'
  );
}

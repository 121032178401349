const stringify = (params) =>
  Object.keys(params)
    .filter((key) => params[key] !== undefined)
    .map((key) => key + '=' + encodeURIComponent(params[key]))
    .join('&');

const parse = (url) => {
  const result = {};
  const urlObj = new URL(url);

  urlObj.searchParams.forEach((value, key) => (result[key] = value));

  return result;
};

export { stringify, parse };
